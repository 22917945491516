body {
  margin: 0;
  padding: 0;
  font-family: 'Product Sans', Arial, sans-serif;
  background-color: #f4f4f4;
}
.container {
  width: 80%;
  margin: 50px auto;
  text-align: center;
}
h1 {
  color: #333;
  font-family: 'Product Sans', Arial, sans-serif;
}
p {
  color: #666;
  font-size: 22px;
  line-height: 1.6;
  font-family: 'Product Sans', Arial, sans-serif;
}
.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3376cc;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: 'Product Sans', Arial, sans-serif;
}
.cta-button:hover {
  background-color: #0056b3;
}
footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  width: 100%;
  text-align: center;
  font-family: 'Product Sans', Arial, sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}


